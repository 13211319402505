import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
    data: any;
    filteredData: any;
    constructor(private http: HttpClient) {}

    ngOnInit() {
        this.http
            .get('assets/data.xlsx', { responseType: 'arraybuffer' })
            .subscribe((data) => {
                const array = new Uint8Array(data);
                let workbook = XLSX.read(array, { type: 'array' });
                let sheet = workbook.Sheets['Sheet1'];
                this.data = XLSX.utils.sheet_to_json(sheet);
                console.log(this.data)
                this.assignCopy();
            });
    }

    assignCopy() {
        this.filteredData = this.data;
    }
    filterItem(value) {
        if (!value) {
            this.assignCopy();
        } // when nothing has typed
        this.filteredData = Object.assign([], this.data).filter((item) => {
            for (let key in item) {
                if (typeof item[key] == "string" && item[key].toLowerCase().indexOf(value.toLowerCase()) > -1) {
                    return true;
                } else if (item[key] == value) {
                    return true;
                }
            }
        });
    }

    isNumber(val){
        return typeof val === 'number';
    }
}
